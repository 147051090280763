<template>
    <default-layout>
        <!-- <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
          <div class="d-flex ps-5 my-auto container text-center">
              <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos">{{monumentoNombres[idiomaIndex].texto}}>> </p>
              <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text" role="button" @click="goToEntradas">{{tipoDeEntrada}}>> </p>
              <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text"><strong>{{nombres[idiomaIndex].texto}}</strong></p>
          </div>
      </div> -->

        <!-- <b-spinner style="position:absolute; z-index:2; left: 40%; top:30%; width: 10rem; height: 10rem;" variant="danger" v-bind:class="{'d-none':!loading}"></b-spinner> -->

        <!-- <div class="container-fluid px-md-5 px-2 mt-4 mb-4" v-bind:class="{'d-none':(visitaFirstDate == null)}"> -->

        <div id="loader" :class="{ 'd-none': !movingToCart }" class="loader-component">
            <img src="../assets/SimboloCastilloRojoCorporativa.svg" style="height:220px; width:auto;"
                class="animated flip infinite" />
            <!-- <h5>Cargando...</h5> -->
        </div>

        <div class="container-fluid px-md-5 px-2 mt-4 mb-4">

            <div class="d-flex pe-0 w-100">
                <div class="text-start pt-3 pe-3" style="width:100%">
                    <h4 class="my-auto pb-0 mb-0 spainhn-title" role="button"><strong>
                            {{ siteData.monumentName }}</strong> </h4>
                    <h5 class="mb-0"><strong class="spainhn-subtitle">{{ siteData.webName }}</strong></h5>
                </div>
            </div>
        </div>

        <div class="container-fluid px-md-5 px-0 mt-0 px-0 mb-4" v-bind:class="{ 'd-none': visitaFirstDate === null }">


            <div class="container-fluid col-md-8 col-12 mt-0  mx-auto" id="horarios-disponibles">

                <b-card class="d-flex pe-0 w-100 container px-0 px-md-0" style="background-color: #D9D9D9;">

                    <div class="d-flex my-auto pb-3 text-center align-items-center">
                        <div class="text-start center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23"
                                fill="none">
                                <path
                                    d="M13.0635 3.39704V1.26267M13.0635 3.39704V5.53141M13.0635 3.39704H8.35138M0.497803 9.80015V19.4048C0.497803 20.5836 1.43545 21.5392 2.59209 21.5392H17.2521C18.4088 21.5392 19.3464 20.5836 19.3464 19.4048V9.80015H0.497803Z"
                                    stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M0.497803 9.80015V5.53141C0.497803 4.35263 1.43545 3.39704 2.59209 3.39704H4.68638"
                                    stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.68628 1.26267V5.53141" stroke="#850B0B" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M19.3464 9.80015V5.53141C19.3464 4.35263 18.4088 3.39704 17.2521 3.39704H16.7285"
                                    stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <!-- <font-awesome-icon icon="fa-calendar" class="fa-2x ms-2"/> -->
                        </div>
                        <div class="d-flex" v-bind:class="{ 'd-none': selectedDate }">
                            <span class="my-auto ms-3 fw-bold spainhn-sections-title">{{
                                $t('default.RESERVATION_STEP_1') }}</span>
                        </div>

                        <div class="d-flex ms-auto" v-bind:class="{ 'd-none': selectedDate == null }">
                            <div class="d-flex my-auto">
                                <div class="ms-auto d-flex">
                                    <span class="my-auto fw-bold spainhn-sections-title">{{ selectedDate ?
                                        selectedDate.toLocaleDateString('es-ES', { timeZone:'UTC'} ):null }}</span>
                                </div>
                                <div class="ms-3">
                                    <font-awesome-icon role="button" v-b-toggle.calendar
                                        class="circle-icon shadow spainhn-sections-title" icon="fa-pen" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <b-collapse id="calendar" class="border-top mt-2"
                        style="border-color: rgb(165, 61, 57) !important; border-width: 2px !important;"
                        v-model="visibleDate">
                        <div class="col-md-8 col-12 mx-auto mt-4 p-md-3">
                            <strong>{{ $t('default.CART_INSTRUCTIONS_1') }}</strong>
                            <vc-date-picker :color="'red'" @update:to-page="newMonth($event)"
                                :locale="selectedIdioma"
                                :disabled-dates="[{ start: null }]" :timezone="'UTC'" :available-dates="enabledDates"
                                v-model='selectedDate' is-expanded @dayclick="daySelected($event)" ref="calendario"
                                is24hr v-bind:class="{ 'd-none': loading }" class="reservasV2-date-picker"
                                :min-date="new Date()" />
                            <!-- <div style="height: 220px;" v-bind:class="{'d-none': !loading}">
                            <mdb-spinner class="d-block mx-auto my-5" big color="red"/>
                        </div> -->

                            <div id="loader" v-if="loading">
                                <img src="../assets/SimboloCastilloRojoCorporativa.svg"
                                    style="height:220px; width:auto;" class="animated flip infinite" />
                                <h5>{{ $t("default.BOOKINGS.LOADING") }}</h5>
                            </div>

                        </div>

                    </b-collapse>
                </b-card>

                <!-- Horarios -->
                <div class="container flex flex-column px-0 my-2">

                    <div class="collapsable-header mx-auto">
                        <!-- Dejamos un solo color el header sin importar si esta activo o no, ya que no aporta mucho -->
                        <!-- <div class="header-title-container" v-bind:class="{'header-active-typhograpy':(visibleHours || horarioSelected !== null), 'header-typhograpy': (!visibleHours && horarioSelected === null) }"> -->
                        <div class="header-title-container header-active-typhograpy">
                            <span class="text-uppercase">
                                {{ horarioSelected === null ? $t('default.RESERVATION_STEP_2') : horarioParaResumen }}
                                <span v-if="!horarioSelected?.hora" style="width: 30px; height: 30px; background-color: inherit;" role="button"
                                    @click="() => { scheduleModal = true }"
                                    v-bind:class="{ 'd-none': horarioSelected == null }">
                                    <font-awesome-icon class="" icon="fa-circle-exclamation" />
                                </span>
                                <p v-bind:class="{'d-none': !horarioSelected?.notaDeVenta}">{{ horarioSelected?.notaDeVenta }}</p>
                            </span>
                            <span style="width: 30px; height: 30px; background-color: inherit;" role="button"
                                @click="() => { visibleHours = !(visibleHours) }"
                                v-bind:class="{ 'd-none': horarioSelected == null }">
                                <font-awesome-icon class="" icon="fa-pen" />
                            </span>
                        </div>
                        <div style="width: 100%; margin-top: 0;" class="animated fadeIn"
                            v-bind:class="{ 'd-none': !visibleHours }">
                            <hr style="color: black; margin-top: 0" />
                            <div style="text-align: start !important;">

                                <div class="d-flex flex-wrap">

                                    <div class="d-flex py-2 my-2 mx-md-2 mx-auto" v-for="(horario, index) in horarios"
                                        v-bind:key="index"
                                        v-bind:class="{ 'd-none': (horario.hora == null || loadingSchedule == true) }">
                                        <div style="background-color:#fcf8e3; width:100%" class="text-start py-2 px-4"
                                            v-bind:class="{ 'horario-selected': isThisHorarioSelected(horario.hora) }">
                                            <mdb-btn color="indigo" size="lg" @click="setTarifa(horario)"
                                                class="spainhn-button my-auto"
                                                v-bind:class="{ 'fa-bounce': (horarioSelected == null) }"
                                                :disabled="(horario.pausado == true)">
                                                <font-awesome-icon icon="fa-clock"
                                                    v-bind:class="{ 'fa-shake': (horarioSelected == null) }"
                                                    size="1x"></font-awesome-icon>
                                                {{ new Date(horario.hora).toLocaleTimeString('es-ES', {
                                                    'timeZone': 'UTC',
                                                hour:'2-digit', minute:'2-digit' } )}}
                                            </mdb-btn>
                                            <small class="spainhn-text my-auto ms-2 w-100">
                                                <span v-bind:class="{ 'd-none': !mostrarAforo }">
                                                    {{ $t("default.MENSAJE_AFORO") }}
                                                </span>
                                                <strong v-bind:class="{ 'd-none': !mostrarPlazas }">
                                                    <span
                                                        v-html="$t('default.MENSAJE_AFORO2', { plazas: getPlazasDisponibles(horario.plazas, horario.plazasOcupadas, horario.plazasReservadas) })"></span>
                                                    <!-- <span class="text-danger">
                                                    {{ getPlazasDisponibles(horario.plazas, horario.plazasOcupadas, horario.plazasReservadas) }}
                                                </span> entradas -->
                                                </strong>
                                                <span class="ms-3" v-bind:class="{'d-none': !horario.notaDeVenta}">{{ horario.notaDeVenta }}</span>
                                            </small>
                                        </div>
                                    </div>

                                </div>

                                <div v-bind:class="{ 'd-none': (horarios.length > 0 || loadingSchedule == true) }">
                                    <p class="text-danger">{{ $t('default.RESERVATION_STEP_2_2') }}</p>
                                </div>

                                <p class="text-start" v-bind:class="{ 'd-none': horariosLimitados.hide }">
                                    <small class="spainhn-small-text">
                                        {{ $t('default.CART_STEP1_NOTES_1') }}<br>
                                        ({{$t('default.CART_STEP1_NOTES_2') }})
                                    </small><br/>
                                    <strong>{{ horariosLimitados.note }}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Tickets -->
                <div class="container flex flex-column px-0 my-2 align-items-center">
                    <div class="collapsable-header mx-auto">
                        <!-- Dejamos un solo color el header sin importar si esta activo o no, ya que no aporta mucho -->
                        <!-- <div class="header-title-container" v-bind:class="{'header-active-typhograpy':(ticketsVisible || getTicketsAmount !== 0), 'header-typhograpy': (!ticketsVisible && getTicketsAmount === 0) }"> -->
                        <div class="header-title-container header-active-typhograpy">
                            <span class="text-uppercase">
                                {{ getTicketsAmount === 0 ? $t('default.RESERVATION_STEP_3') : getTicketsAmount }}
                            </span>
                            <span style="width: 30px; height: 30px; background-color: inherit;" role="button"
                                @click="() => { ticketsVisible = !(ticketsVisible) }"
                                v-bind:class="{ 'd-none': getTicketsAmount === 0 }">
                                <font-awesome-icon class="" icon="fa-pen" />
                            </span>
                        </div>

                        <div style="width: 100%; margin-top: 0;" class="animated fadeIn"
                            v-bind:class="{ 'd-none': !ticketsVisible }">
                            <hr style="color: black; margin-top: 0" />
                            <div style="text-align: start !important;">
                                <div class="w-100 spainhn-text pt-2 pb-2 px-2">
                                    <strong>{{ $t('default.RESERVATION_STEP_3_1') }}</strong>
                                </div>

                                <div class="w-100 pe-3 text-wrap text-start spainhn-small-text" v-if="tipoCobro"
                                    v-bind:class="{ 'd-none': !tipoCobro }">
                                    <small>
                                        {{ $t("default.MENSAJE_PRECIOS_INCLUYEN") }} {{ comision?.toLocaleString('es-ES',
                                        { maximumFractionDigits: 2 } )}} {{ simbolo }}+IVA {{
                                        $t("default.MENSAJE_EN_CONCEPTO") }}
                                    </small>
                                </div>

                                <div v-bind:class="{ 'd-none': !precios.length }" id="precios-disponibles">

                                    <mdb-container class="border-bottom py-2 border-top"
                                        v-for="(precio, index) in precios" v-bind:key="index">
                                        <mdb-row class="justify-content-between">
                                            <mdb-col col="12" md="6">
                                                <div class="d-flex flex-column mx-auto text-center">
                                                    <strong class="spainhn-text">
                                                        {{ precio.descripcion }}
                                                        <span v-if="precio.helpers?.length && precio.help">
                                                            <mdb-tooltip material trigger="hover">
                                                                <span slot="tip">{{precio?.help}}</span>
                                                                <font-awesome-icon slot="reference"
                                                                    icon="question-circle" />
                                                            </mdb-tooltip>
                                                        </span>
                                                    </strong>
                                                    <small
                                                        class="spainhn-text">{{ (priceCalculator(precio.precio)).toLocaleString(
                                                        'es-ES', { style: 'currency', 'currency': 'EUR'} ) }} </small>
                                                </div>
                                            </mdb-col>
                                            <mdb-col col="12" md="6">
                                                <botones-reserva ref="botoneras"
                                                    :min-personas="selected[index].minPersonas" :tarifa="index"
                                                    @change="(evt) => preciosEdit(evt)" :maximo-incremento="-1" />
                                            </mdb-col>

                                        </mdb-row>
                                    </mdb-container>
                                    <div class="clearfix">
                                        <mdb-btn color="indigo" v-bind:class="{ 'd-none': !precios.length }"
                                            class="spainhn-button mt-3 mb-2 ms-auto float-right" size="lg"
                                            @click="editarResumen" :disabled="productoAgregado"><strong>{{
                                                $t('default.SPAINHN_BUTTON_ADD_CART') }}</strong></mdb-btn>
                                    </div>

                                </div>

                                <div v-bind:class="{ 'd-none': precios.length }"
                                    class="mt-2 border px-3 py-4 border-warning mb-3" style="background-color:#fcf8e3">
                                    <div class="row">
                                        <div class="col-md-2 col-12 my-auto">
                                            <div class="d-flex">
                                                <font-awesome-icon class="my-auto text-warning mx-auto"
                                                    icon="fa-circle-exclamation" size="3x"></font-awesome-icon>
                                            </div>
                                        </div>
                                        <div class="col-10 mx-auto">
                                            <p><strong class="text-spainhn my-auto">{{
                                                    $t("default.MENSAJE_SELECCIONAR_HORARIO") }}</strong></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <seats-configurator v-if="seats" :expanded="visibleSeats" :zonas="zonasInfo"
                    :personas="getTicketsAmount" @getAsientos="(evt) => setAsientos(evt)"
                    :originLayout="originLayout" :imagenIlustrativa="imagenIlustrativa">

                </seats-configurator>

                <div class="container flex flex-column px-0 my-2 align-items-center">
                    <div>
                        <span class="spainhn-sections-title" v-if="total">
                            {{ total.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) }}
                        </span>
                        <b-button class="finish-button mx-auto text-white"
                            :disabled="!habilitarPasoACarrito || movingToCart" @click="goToCarrito">
                            <span class="finish-text">
                                {{ $t('default.RESERVATION_STEP_5') }}
                            </span>
                        </b-button>
                    </div>
                </div>
            </div>

        </div>

        <div class="container-fluid px-5 mt-4 mb-4" v-bind:class="{ 'd-none': visitaFirstDate !== null }">
            <div class="row margen-estandard d-flex">
                <div class="col-md-7 h-100 my-auto">
                    <div class="text-start d-flex pt-3 flex-column h-100 mt-auto" style="width:100%">
                        <h4 class="my-3 pb-0 mb-0 spainhn-subtitle-black mx-auto mt-auto"
                            v-bind:class="{ 'd-none': loading && paquetes.length === 0 }"><strong> {{ $t("default.SERVICES_BAR_ENTRADAS")
                                }}</strong> </h4>
                        <p class="my-3 pb-0 mb-0 spainhn-title mx-auto spainhn-title-text3" role="button"
                            v-bind:class="{ 'd-none': loading }"><strong>
                                {{ siteData.monumentName }}</strong> </p>
                        <p class="mb-0 mx-auto" v-bind:class="{ 'd-none': loading }"><strong
                                class="spainhn-subtitle">{{ siteData.webName }}</strong></p>
                        <div class="mx-auto">
                            <recommendations-card
                                v-for="(paqueteM,index) in paquetes" :key="index"
                                :title="paqueteM.name"
                                :showDefault="false"
                                :text="$t('default.CARDS.PLACE', { msg: paqueteM.monumentoId.nombre})"
                                :price-description="paqueteM.precioOrientativo ? 
                                    $t('default.CARDS.START_FROM', {msg: `${parseFloat(Number(paqueteM.precioOrientativo))?.toLocaleString( 'es-ES', { style: 'currency', currency: 'EUR' } )}`}) : 
                                    ''"
                                :image-url="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                                :showBuyButton="true"
                                @goTo="() => goToMicrositioPaqueteMonumento( { name: paqueteM.name, id: paqueteM._id } )"
                                @buy="() => goToBuy( paqueteM, true )"
                                />
                        </div>

                        <div class="pt-0 d-flex my-1 mx-auto" style="margin-left:6rem;width:200px">
                            <img src="../assets/LOGO REPAHIS.png" class="img-fluid my-auto"
                                style="height:80px;width:200px" v-bind:class="{ 'd-none': loading }" />
                        </div>

                        <p class="mx-auto" v-bind:class="{ 'd-none': loading }">{{
                            $t("default.MENSAJE_SUGERENCIA_ACTIVIDAD") }}</p>

                        <p class="mx-auto" v-bind:class="{ 'd-none': loading }">{{
                            $t("default.MENSAJE_AYUDA_PLANIFICACION") }}</p>
                        <mdb-btn color="indigo" class="spainhn-button mx-auto" @click="goToContact" href="#"
                            v-bind:class="{ 'd-none': loading }">{{ $t("default.MENSAJE_ORGANIZA_TU_RESERVA")
                            }}</mdb-btn>

                    </div>
                </div>

                <div class="d-flex flex-column pt-3 col-12 col-md-5 col-xxl-4" v-bind:class="{ 'd-none': loading }">
                    <strong class="text-center mx-auto w-100 spainhn-subtitle-2">{{
                        $t('default.MICROSITES_TICKETS_RECOMMENDATIONS') }}</strong>
                    <div class="w-100 border-bottom"
                        style="height:2vh; border-color:var(--spainhn-rojo-corporativo) !important; border-width: medium;">
                    </div>
                    <div class="d-flex flex-wrap">
                        <!-- Poner aquí las visitas -->
                        <cards-thumbnails-3 v-for="(evento, index) in eventosRecomendados" v-bind:key="index"
                            :monument="evento.webName" :nombreUrl="evento.visitaId" @goTo="goToEntrada" :tipo="'Entrada'"
                            :text="evento.webDescription"
                            :imageUrl="`${imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`"
                            v-bind:class="{ 'd-none': index > 2 }" />
                    </div>

                </div>

            </div>
        </div>

        <mdb-container fluid class="mt-3">
            <ComponentsCloud class="w-100" />
        </mdb-container>

        <b-modal v-model="reservaCancelada" id="mensajes" class="col-md-4 col-md-offset-4 col-xs-8 col-xs-offset-2"
            hide-footer hide-header centered>
            <div class="col-xs-12" style="text-align: right;">
                <button @click="() => reservaCancelada = false" type="button">
                    <img src="@/assets/close.svg" width="14px" height="14px">
                </button>
            </div>
            <p class="alert sys-message" role="alert"></p>
            <div class="col-xs-12 justify-content-center">
                <div class="d-flex">
                    <img class="mx-auto" src="@/assets/cancel-face.svg" width="50px" height="50px">
                </div>
                <div class="d-flex">
                    <p class="mx-auto spainhn-text">{{ $t("default.BOOKINGS.BOOKING_CANCEL") }}</p>
                </div>
                <div class="d-flex">
                    <mdb-btn color="indigo" class="mx-auto spainhn-button"
                        @click="() => reservaCancelada = false">{{ $t("default.BUTTONS.ACCEPT") }}</mdb-btn>
                </div>
            </div>
        </b-modal>

        <ScheduleMonumentModal danger :title="$t('default.MICROSITES_SCHEDULE')" html :description="horario" :popupStart="scheduleModal" @close="() => scheduleModal = false"/>

        <WelcomeModal :popupStart="mostrarMensajeDeWelcome" :title="welcomeMessageTitle"
            :subtitle="welcomeMessageSubtitle" @close="() => mostrarMensajeDeWelcome = false" />
        <error-alert :show="asientosOcupadosAlert" :mensaje="$t('default.SORRY_SEATS_MESSAGE')"
            @close="() => refrescarAsientos()" />
        <success-alert :show="itemAgregado" @close="cerrarConfirmacion"
            :mensaje="$t('default.BOOKINGS.BOOKING_TO_CART')"></success-alert>

        <error-alert :show="itemEliminado" @close="() => itemEliminado = false"
            :mensaje="$t('default.BOOKINGS.BOOKING_REMOVED')"></error-alert>
        <error-alert :cancel="true" :carrito="true" :show="itemDuplicadoAlert" @close="duplicarItem"
            @cancelar="cancelarOperacion"
            :mensaje="$t('default.BOOKINGS.BOOKING_AUTO_CANCEL')" />
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
// import { uuid as uuid } from "vue-uuid";
import { v4 as uuidv4 } from 'uuid';
import CardsThumbnails3 from '../components/CardsThumbnails3.vue'
import SuccessAlert from '../components/SuccessAlert.vue';
import ErrorAlert from '../components/ErrorAlert.vue';
import ClorianService from '../services/ClorianService';
import RecommendationsCard from '../components/RecommendationsCard.vue';
// import { v4 as uuidv4 } from 'uuid';

import moment from 'moment-timezone'
import BotonesReserva from '../components/BotonesReserva.vue';
import EventBus from '../event-bus';
import Seats from '../components/Seats.vue'

import WhiteLoader from '../components/Loaders/WhiteLoader'
import MinimalistBreadcrumb from '../components/Breadcrumbs/MinimalistBreadcrumb'
import CollapsableElement from '../components/CollapsableElement.vue';
import ComponentsCloud from '../components/ComponentsCloud'
import WelcomeModal from '../components/WelcomeModal.vue';
import ScheduleMonumentModal from '../components/ScheduleMonumentModal.vue';
// import { uuidEmit } from 'uuid-timestamp';

export default {
    components: {
        DefaultLayout,
        CardsThumbnails3,
        SuccessAlert,
        ErrorAlert,
        BotonesReserva,
        'seats-configurator': Seats,
        ComponentsCloud,
        WelcomeModal,
        RecommendationsCard,
        ScheduleMonumentModal
    },
    data() {
        return {
            selectedDate: null,
            consultandoDisponibilidad: false,
            monumentoNombres: [{ texto: "" }, { texto: "" }, { texto: "" }, { texto: "" }],
            enabledDates: [],
            monumentoId: null,
            visitaId: null,
            nombres: [{ texto: "" }, { texto: "" }, { texto: "" }, { texto: "" }],
            idiomaIndex: 0,
            precios: [],
            resumen: [],
            selected: [],
            cantidadBoletos: 0,
            eventosRecomendados: [],
            tipoDeEntrada: "Tarifas",
            disponibilidadInfo: null,
            cargandoDisponibilidad: true,
            horariosInfo: [],
            horarioSelected: null,
            scheduleModal: false,
            itemUuid: null,
            tipoComision: null,
            comision: null,
            visitaFirstDate: null,
            fechasDesactivadas: [{ 'start': null }],
            resumenFields: [
                { key: "cantidad", label: 'Cantidad' },
                { key: "descripcion", label: "Tipo" },
                { key: "precio", label: "Precio Ud." },
                { key: "total", label: "Precio Total" }
            ],
            attrs: [
                {
                    contentStyle: {
                        color: 'red'
                    }
                }],
            total: 0,
            baseTotal: 0,
            comisionTotal: 0,
            descuentoTotal: 0,
            condicionComercial: null,
            descuentoComercial: null,
            tipoCobro: true,
            iva: 0.21,
            cancelable: false,
            loading: true,
            loadingSchedule: false,
            imagenesUrl: null,
            simbolo: "%",
            productoAgregado: false,
            itemAgregado: false,
            itemEliminado: false,
            carritoItemTemp: null,
            itemDuplicadoAlert: false,
            mostrarAforo: false,
            reservaCancelada: false,
            mostrarPlazas: false,
            clorianRatesInfo: null,
            dateSelected: new Date(),
            clorian: false,
            pos: null,
            visibleDate: true,
            visibleHours: false,
            ticketsVisible: false,
            visibleCheckout: false,
            cantidades: [],
            cantidadTotal: 0,
            tarifaId: null,
            seats: false,
            visibleSeats: false,
            zonasInfo: [],
            selectedSeats: [],
            zonaDeBoletos: null,
            numeroDeAsientos: null,
            imagenIlustrativa: null,
            originLayout: null,
            asientosOcupadosAlert: false,
            cupon: null,
            applyingCupon: false,
            cuponDeDescuentoAgregado: false,
            cuponDeDescuentoError: false,
            noChargeForFreeTickets: false,
            cantidadesReady: false,
            autocompensar: true,
            metodoDeFacturacion: 'visita',
            welcomeActive: false,
            welcomeMessageTitle: "",
            welcomeMessageSubtitle: "",
            mostrarMensajeDeWelcome: false,
            welcomeMessageImage: "",
            movingToCart: true,
            consultarBienvenida: false,
            restringirVentaIndividual: false,
            paquetes: [],
            siteData: {

            }
        }
    },
    async mounted() {
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    methods: {
        async verificarCupon() {
            this.applyingCupon = true;
            const result = await ApiService.validateCoupon(this.cupon);

            const cuponInfo = result.data;
            const cuponesEnCarrito = this.$store.getters.getCouponsInCart;

            if (cuponesEnCarrito.length) {
                if (cuponesEnCarrito.includes(cuponInfo.codigo)) {
                    this.applyingCupon = false;
                    this.cuponDeDescuentoError = true;
                    this.cupon = null;
                    return;
                }

            }

            if (cuponInfo.used != true && cuponInfo.event == this.$route.params.entradaId && cuponInfo.bloqueado != true) {

                let tempTotal = this.total - cuponInfo.discount;
                let tempBaseTotal = this.baseTotal - cuponInfo.discount;

                this.total = tempTotal >= 0 ? tempTotal : 0;

                this.baseTotal = tempBaseTotal >= 0 ? tempBaseTotal : 0;

                this.cuponDeDescuentoAgregado = true;

            }
            else {
                this.cuponDeDescuentoError = true;
                this.applyingCupon = false;
                this.cupon = null;
            }
        },
        refrescarAsientos() {
            this.asientosOcupadosAlert = false;
            window.location.reload();
        },
        scrollToRates() {
            this.$refs["ratesPicker"].scrollIntoView({ behavior: "smooth" })
        },
        // Or create a reusable function
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        convertMsToTime(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;

            // 👇️ If you don't want to roll hours over, e.g. 24 to 00
            // 👇️ comment (or remove) the line below
            // commenting next line gets you `24:00:00` instead of `00:00:00`
            // or `36:15:31` instead of `12:15:31`, etc.
            hours = hours % 24;

            return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
        },

        getPlazasDisponibles(plazas, plazasOcupadas, plazasReservadas) {
            if (plazas != null && plazas != 0) {
                let ocup = plazasOcupadas ?? 0;
                let reserv = plazasReservadas != null && plazasReservadas >= 0 ? plazasReservadas : 0;

                if (reserv < 0) {
                    reserv = 0;
                }
                // if(!plazasOcupadas || !plazasReservadas){
                //     return plazas;
                // }
                // else{
                return plazas - ocup - reserv;
                // }
            }
            else {
                return 'Sin límite'
            }
        },

        cerrarConfirmacion() {
            let $vm = this;
            $vm.itemAgregado = false;
        },

        isThisHorarioSelected(hora) {
            let $vm = this;
            if ($vm.horarioSelected) {
                if ($vm.horarioSelected.hora == hora) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },

        preciosEdit(evt) {
            this.cantidades[evt.tarifa] = evt.cantidad;

            this.cantidadesReady = this.cantidades.reduce((acc, curr) => acc + curr, 0) > 0 ? true : false;
        },

        valueChange(newValue, index) {
            let $vm = this;
            let valorAnterior = this.selected[index].cantidad;

            if (newValue < 0) {
                // this.$emit('input', 0)
                this.selected[index].cantidad = 0;
            } else {
                // this.$emit('input', newValue)
                let prev = this.selected[index].cantidad;
                this.selected[index].cantidad = newValue;

                let cantidadTotal = this.selected.map(cant => cant.cantidad).reduce((acc, prev) => acc + prev);

                let plazasOcupadas = $vm.horarioSelected.plazasOcupadas ?? 0;

                let plazasReservadas = $vm.horarioSelected.plazasReservadas != null && $vm.horarioSelected.plazasReservadas >= 0 ? $vm.horarioSelected.plazasReservadas : 0;

                if (cantidadTotal > ($vm.horarioSelected.plazas - plazasOcupadas - plazasReservadas) && ($vm.horarioSelected.plazas != 0 && $vm.horarioSelected.plazas != null)) {

                    this.selected[index].cantidad = prev;
                }
            }
            this.validarNumeroEntradas(this.selected[index].cantidad, index, valorAnterior);
        },

        setAsientos(payload) {
            this.visibleCheckout = true;
            this.visibleSeats = false;
            this.selectedSeats = payload.asientosInfo;
            this.zonaDeBoletos = payload.zonaName;
            this.numeroDeAsientos = payload.asientosResumen;

            this.scrollToRates();
        },
        // Guarda el elemento en el carrito, podemos utilizar el id de la fecha para identificar las entradas
        async editarResumen() {
            let $vm = this;

            // Aquí se guardan los tipos de entradas que quiere adquirir el cliente
            $vm.resumen = [];

            // Selected es la variable que guarda el número seleccionado de entradas para cada una de los diferentes
            // precios
            $vm.selected.forEach((item, index) => {

                item.cantidad = this.cantidades[index];
                if (item.cantidad > 0) {
                    $vm.resumen.push(item);
                    let importeBase = null;
                    importeBase = item.cantidad * item.precio;
                    item.importeBase = importeBase;
                    item.total = item.cantidad * item.pvp;
                    item.descuento = item.tipo_comision == 2 ? item.descuento / 100 : (item.descuento ?? 0);
                    item.comision = item.tipo_comision == 3 ? item.comision / 100 : (item.comision ?? 0);
                    item.autocompensado = this.autocompensar ?? true;
                }
            })

            this.baseTotal = this.resumen.map(r => r.importeBase).reduce((acc, curr) => acc + curr, 0);
            this.total = this.resumen.map(r => r.total).reduce((acc, curr) => acc + curr, 0);
            this.ticketsVisible = false;

            if (this.seats) {
                this.visibleSeats = true;
                this.scrollToRates();
            }
            else {
                this.visibleCheckout = true;
            }

            if(!this.horarioSelected.hora){
                this.scheduleModal = true;
            }
        },

        removeEntrada() {
            let $vm = this;
            $vm.resumen = [];
            if ($vm.itemUuid) {
                $vm.$store.commit('removeFromCart', $vm.itemUuid);
                // window.alert("Item eliminado del carrito");
                $vm.itemEliminado = true;
            }

            $vm.total = 0;
            $vm.productoAgregado = false;
        },
        verificarDuplicidad(nuevoItem) {
            let $vm = this;
            let entradas = $vm.$store.getters.itemsInCart;
            let entradasDuplicadas = [];
            entradasDuplicadas = entradas.filter(entrada => {
                if ((entrada.itemIDVisita == nuevoItem.itemIDVisita) && (entrada.itemFecha == nuevoItem.itemFecha)) {
                    return true;
                }
                else {
                    return false;
                }
            })

            if (entradasDuplicadas.length > 0) {
                return true;
            }
            else {
                return false;
            }
        },
        goToCastillosYPalacios() {
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos() {
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.monumentoNombres[0]?.texto)
        },

        async verificarAsientosDisponibles() {

            const filas = this.selectedSeats.map(s => s.filaNombre);
            const uniqueFilas = filas.filter((f, index, arry) => {
                return arry.indexOf(f) === index
            })

            const seatsId = this.selectedSeats.map(s => s.columna);
            const result = await ApiService.verifySeats(seatsId, this.zonaDeBoletos, this.horarioSelected.configuracionAsientos, uniqueFilas);
            return result.data.available;
        },
        async goToCarrito() {
            let $vm = this;

            this.movingToCart = true;

            // Luego tenemos que ver si se trata de una venta con comisión o si se trata de una venta sin comisión
            if ($vm.condicionComercial) {
                $vm.comisionTotal = $vm.total - $vm.baseTotal;
            }
            else {
                $vm.comisionTotal = 0;
            }

            // En caso de que se haya escogido una reserva con descuento comercial, se calcula, si no se asigna el valor de 0
            $vm.descuentoTotal = $vm.descuentoComercial ? ($vm.descuentoComercial.valor / 100 * ($vm.total)) * (1 + $vm.iva) : 0

            // Creamos el identificador único de esta reserva
            $vm.itemUuid = uuidv4()

            let comisionComercial = null;
            let descuentoComercial = null;

            if ($vm.tipoCobro == true) {
                comisionComercial = $vm.comision;
            }
            else {
                descuentoComercial = null;
            }

            this.consultandoDisponibilidad = true;

            if (this.seats) {
                const check = await this.verificarAsientosDisponibles();

                this.consultandoDisponibilidad = false;
                if (check === false) {
                    this.asientosOcupadosAlert = true;
                    return;
                }

            }

            let itemId = null

            // Está condición sirve para interactuar con clorian, si la url contiene el nombre del monumento, entonces, se trata de una url relacionada con clorian

            let nombreDeMonumentoQuery = $vm.$route.query.monumento;

            if (nombreDeMonumentoQuery) {
                itemId = $vm.horarioSelected.eventId;
            }
            else {
                itemId = $vm.horarioSelected._id
            }

            // console.log( this.selectedDate.setUTCHours( 0 ) );
            // console.log( moment( this.selectedDate ).tz('Europe/Madrid').startOf( 'd' ).toDate() )

            let carritoItem = {
                itemID: $vm.clorian == false ? itemId : null,
                itemClorianID: $vm.clorian ? itemId : null,
                itemIDVisita: $vm.$route.params.entradaId,
                itemUuid: $vm.itemUuid,
                itemTarifaId: this.tarifaId,
                itemComisionTotal: $vm.comisionTotal,
                itemDescuentoTotal: $vm.descuentoTotal,
                itemBaseTotal: $vm.baseTotal,
                itemHorario: $vm.horarioSelected.hora,
                itemFecha: moment($vm.selectedDate.setUTCHours(0)).tz('Europe/Madrid').startOf('d').format(),
                itemTotal: $vm.total,
                itemTickets: $vm.resumen,
                itemVisita: $vm.siteData.webName,
                itemMonumentoId: this.monumentoId,
                itemMonumento: $vm.siteData.monumentName,
                itemComision: $vm.comision,
                itemTipoCobro: $vm.tipoCobro,
                itemComisionPorcentaje: $vm.tipoComision,
                cancelable: $vm.cancelable,
                seguroCancelacion: false,
                montoCancelacion: 0,
                timestamp: new Date(),
                pos: $vm.pos,
                carritoUuid: null,
                reservationId: null,
                asientos: this.selectedSeats,
                numeroDeAsientos: this.numeroDeAsientos,
                zonaDeBoletos: this.zonaDeBoletos,
                cupon: this.cupon,
                metodoDeFacturacion: this.metodoDeFacturacion ?? 'visita'
            }

            const preReservas = [
                {
                    uuid: carritoItem.itemUuid,
                    pax: carritoItem.itemTickets.map(item => item.cantidad).reduce((acc, prev) => acc + prev, 0),
                    eventoId: $vm.clorian == false ? carritoItem.itemID : null,
                    eventoClorianId: $vm.clorian ? carritoItem.itemID : null
                }
            ]

            const carritoObjItem = {
                uuid: uuidv4(),
                status: false,
                preReservas: preReservas,
            }

            if (!$vm.verificarDuplicidad(carritoItem)) {

                await $vm.$store.dispatch('addToCart', carritoItem);

                carritoItem.carritoUuid = $vm.$store.getters.cartStatus.uuid;

                console.log(carritoItem.carritoUuid);
                if ($vm.clorian == true) {
                    // Aquí enviamos la preserva a clorian.

                    try {

                        const res = await ClorianService.createReservation($vm.entradaId, $vm.monumentoNombres[0].texto, carritoItem.itemTickets, itemId, carritoItem.itemUuid)
                        this.$store.commit('addReservationId', { itemUuid: carritoItem.itemUuid, reservationId: res.data.purchase.reservationList[0].reservationId });
                    }
                    catch (err) {
                        console.log(err);
                    }
                    // window.alert("Solo tiene 15 minutos para completar su compra")
                    await $vm.consultarInfoClorian();

                }
                else {
                    await $vm.consultarInfoParaReserva();
                }
                // Esta variable hace que no se puedan agregar más entradas con estos mismos datos
                $vm.productoAgregado = true;
                // Esta entrada hace que el icono de carrito parpadeé para llamar la atención;
                $vm.itemAgregado = true;
            }
            else {
                $vm.carritoItemTemp = carritoItem;
                $vm.itemDuplicadoAlert = true;
            }

            $vm.$router.push('/carrito')
        },
        goToEntrada(evento) {
            let $vm = this;
            $vm.$router.push("/castillos/tarifas/" + $vm.monumentoNombres[0]?.texto + '/' + evento)
        },
        goToEntradas(evento) {
            let $vm = this;
            if ($vm.tipoDeEntrada == "Experiencia") {
                $vm.$router.push("/experiencias-exclusivas/" + $vm.monumentoNombres[0]?.texto)
            }
            else if ($vm.tipoDeEntrada == "Evento") {
                $vm.$router.push("/eventos-singulares/" + $vm.monumentoNombres[0]?.texto)
            }
            else {
                $vm.$router.push("/castillos/tarifas/" + $vm.monumentoNombres[0]?.texto)
            }
        },
        goToContact() {
            let $vm = this;
            $vm.$router.push({
                name: 'contactReserva',
                query: {
                    monumento: $vm.monumentoNombres[$vm.idiomaIndex]?.texto,
                    tipo: $vm.tipoDeEntrada,
                    visita: $vm.nombres[$vm.idiomaIndex]?.texto
                }
            })
        },
        masRutas() {
            let $vm = this;
            $vm.$router.push("/castillos/rutas/" + $vm.monumentoId)
        },
        // Este evento es disparado cuando se detecta un cambio de mes en el calendario.
        async newMonth(event) {

            if (this.loading === true) {
                return;
            }

            if( this.restringirVentaIndividual ){
                this.visitaFirstDate = null;
                return;
            }
            let $vm = this;
            let today = new Date();
            let fromDate = null;
            let mesActual = today.getMonth() + 1;
            let yearInCourse = today.getFullYear();
            let month = event.month;
            let year = event.year;

            // Si el mes es igual al mes en el que estamos, limitamos la consulta a la fecha actual.
            if (month == mesActual && year == yearInCourse) {
                if (yearInCourse == year) {
                    fromDate = today;
                }
                else {
                    fromDate = new Date(year, month - 1, 1);
                }
            }
            // Si es un mes superior al actual, pedimos la información desde el primer día del mes
            else {
                fromDate = new Date(year, month - 1, 1);
            }

            let nombreDeMonumentoQuery = $vm.$route.query.monumento;

            if (nombreDeMonumentoQuery) {
                $vm.consultarInfoClorian(month);
            }
            else {
                await $vm.getFechas(fromDate)
            }

        },
        getDisponiblidad() {

        },
        // Obtenemos las fechas disponibles para el mes con el que se está trabajando
        async getFechas(fromDate, autoFocus = true) {

            if( this.restringirVentaIndividual === true ){
                this.visitaFirstDate = null;
                return;
            }

            let fromDateStandard = moment(fromDate).format('YYYY-MM-DD');

            fromDateStandard += 'T00:00:00.000Z'
            let $vm = this;

            $vm.loading = true;

            $vm.enabledDates = [];
            $vm.precios = [];

            try {

                // Hacemos la consulta al servidor, le pasamos como datos el idioma, el id de la entrada que queremos conocer y
                // la fecha a partir de la que se quiere consultar, nos va a devolver los valores para dos meses seguidos
                const result = await ApiService.getFechaByVisitaId($vm.$i18n.locale, $vm.entradaId, fromDateStandard);
                $vm.loading = false
                // La información de dispoinibilidad se utiliza para determinar que fechas se van a marcar como disponibles en el calendario
                $vm.disponibilidadInfo = [...result.data.fecha];

                $vm.disponibilidadInfo = $vm.disponibilidadInfo.sort(function (a, b) {
                    return new Date(a.fecha) - new Date(b.fecha);
                })
                // $vm.visitaFirstDate = result.data.visitaExist;
                $vm.visitaFirstDate = result.data.visitaExist;

                let tempHorariosInfo = null;

                let now = moment();

                $vm.disponibilidadInfo.forEach(element => {
                    let dateObject = { 'start': element.fecha, 'end': element.fecha };
                    let horariosDisponibles = []

                    // let now = moment();
                    horariosDisponibles = element.horarios.filter(horario => {
                        if (horario.pausado) {
                            return false
                        }
                        else if (horario.horaDeCierre) {
                            if (moment.utc(horario.horaDeCierre).isBefore(now)) {
                                return false
                            }
                            else {
                                return true;
                            }
                        }
                        else {
                            return true;
                        }
                    })

                    if (horariosDisponibles.length > 0) {
                        $vm.enabledDates.push(dateObject);
                    }
                });

                // El tipo de entrada se muestra en la barra de resultados
                $vm.tipoDeEntrada = $vm.disponibilidadInfo[0] ? $vm.disponibilidadInfo[0].tipo : $vm.tipoDeEntrada;

                let todayIsOk = true;
                // Si es una entrada abierta, la hora de visita puede ser cualquiera y solo hay una
                // hora en la información devuelta, por lo que se fija la tarifa disponible de manera automática
                if ($vm.disponibilidadInfo[0]) {
                    if ($vm.disponibilidadInfo[0].horarios.length == 1 && $vm.disponibilidadInfo[0].horarios[0].hora == null) {
                        let now = moment();
                        const lastHour = $vm.disponibilidadInfo[0].horarios.length - 1
                        const horario = $vm.disponibilidadInfo[0].horarios[lastHour];
                        if (horario.horaDeCierre) {
                            if (moment.utc(horario.horaDeCierre).isAfter(now)) {
                                //   $vm.setTarifa($vm.disponibilidadInfo[0].horarios[0]);
                                todayIsOk = true;
                            }
                            else {
                                todayIsOk = false;
                            }
                        }
                    }
                }
                const calendar = $vm.$refs['calendario'];

                let fecha = null;

                if (todayIsOk) {
                    fecha = $vm.disponibilidadInfo[0] ? $vm.disponibilidadInfo[0].fecha : null;
                    tempHorariosInfo = $vm.disponibilidadInfo[0] ? [...$vm.disponibilidadInfo[0].horarios] : [];
                }
                else {
                    fecha = $vm.disponibilidadInfo[1] ? $vm.disponibilidadInfo[1].fecha : null;
                    tempHorariosInfo = $vm.disponibilidadInfo[1] ? [...$vm.disponibilidadInfo[1].horarios] : [];
                }

                $vm.horariosInfo = tempHorariosInfo.filter(hora => {
                    if (hora.horaDeCierre) {
                        if (moment.utc(hora.horaDeCierre).isBefore(now)) {
                            return false
                        }
                        else {
                            return true;
                        }
                    }
                    else if (hora.hora) {
                        let date = moment.utc($vm.disponibilidadInfo[0].fecha).format("YYYY-MM-DD");

                        let horario = moment.utc(hora.hora).format("HH:mm:ss");
                        let newHour = moment(date + "T" + horario)

                        if (now.format("YYYY-MM-DD") === date) {
                            var isAfter = moment(newHour).isAfter(now);
                            return isAfter;

                        }
                        else {
                            return true
                        }
                    }
                    else {
                        return true;
                    }
                })

                $vm.horariosInfo = $vm.horariosInfo.map(hora => {
                    let plazasReservadas = hora.plazasReservadas != null && hora.plazasReservadas >= 0 ? hora.plazasReservadas : 0;
                    if (hora.plazas > 0) {
                        if (hora.plazas <= (hora.plazasOcupadas + plazasReservadas)) {
                            hora.pausado = true
                        }
                    }
                    else {
                        hora.pausado = false
                    }

                    return hora;
                })

                $vm.selectedDate = fecha ? new Date(fecha) : null;

                if (fecha) {
                    calendar.focusDate(new Date(fecha));
                }

                this.selectedDate = null;
                $vm.cargandoDisponibilidad = false;
            }
            catch (err) {
                this.loading = false;
                console.log(err);
            }
        },

        resetBoletos() {
            try {
                this.$store.commit('resetBoletos')
                this.cantidades = [];
                // this.$refs['botoneras'].reset();
                EventBus.$emit("resetBoletos");
            }
            catch (err) {
                console.log(err);
            }
        },
        async setTarifa(horario) {
            let $vm = this;

            this.resetBoletos();
            $vm.loading = false;
            $vm.horarioSelected = horario;

            if (horario.notaDeVenta) {
                this.$toast.info(horario.notaDeVenta, { autoClose: 4000})
            }

            const plazasDisponibles = this.getPlazasDisponibles(horario.plazas, horario.plazasOcupadas, horario.plazasReservadas);

            if (horario.configuracionAsientos) {

                const result = await ApiService.getZonasConfig(horario.configuracionAsientos);
                this.zonasInfo = [];

                const zonas = result.data.zonas;

                this.zonasInfo = zonas.map(z => {

                    const asientosOcupados = z.asientosOcupados ?? 0;
                    const asientosReservados = z.asientosReservados ?? 0;
                    const obj = {
                        _id: z._id,
                        name: z.nombre,
                        color: z.color,
                        filas: z.filas,
                        orden: z.orden,
                        asientos: (z.asientos - asientosOcupados - asientosReservados)
                    }
                    return obj;
                });


                this.zonasInfo.sort(function (a, b) {
                    const orden1 = a.orden ?? 0;
                    const orden2 = b.orden ?? 0;
                    return a.orden - b.orden;
                })
                // const imagenIlustrativa = result.data.imagen;

                this.imagenIlustrativa = result.data.imagen;
                this.originLayout = result.data.plantillaId;

                this.visibleSeats = false;
            }

            if (plazasDisponibles === 'Sin límite') {
                this.$store.commit('guardarPlazas', null);
            }
            else {
                this.$store.commit('guardarPlazas', plazasDisponibles);

            }

            this.visibleHours = false;

            $vm.ticketsVisible = true;

            let nombreDeMonumentoQuery = $vm.$route.query.monumento;

            this.precios = [];

            // Esto quiere decir que el monumento está configurado para funcionar con clorian
            if (nombreDeMonumentoQuery) {

                let preciosTemp = $vm.clorianRatesInfo;

                $vm.precios = preciosTemp.map(precio => {
                    return {
                        descripciones: [{ texto: precio.shortName }, { texto: precio.shortName }, { texto: precio.shortName }, { texto: precio.shortName }],
                        buyerTypeId: precio.buyerTypeId,
                        precio: parseFloat(precio.price),
                    }
                })

                $vm.selected = [];

                $vm.precios.forEach(item => {
                    let precio = item.precio;
                    let comision = null;
                    let descuento = null;

                    if ($vm.tipoCobro) {
                        if (precio > 0) {
                            comision = $vm.comision;
                        }
                        else {
                            if (this.noChargeForFreeTickets) {
                                comision = 0;
                            }
                            else {
                                comision = this.comision;
                            }
                        }
                    }
                    else {
                        if (precio > 0) {
                            descuento = $vm.comision
                        }
                        else {
                            if (this.noChargeForFreeTickets) {
                                descuento = 0;
                            }
                            else {
                                descuento = this.comision;
                            }
                        }
                    }
                    this.cantidades.push(0)
                    $vm.selected.push({
                        'cantidad': 0, buyerTypeId: item.buyerTypeId,
                        descripcion: item.descripciones[$vm.idiomaIndex].texto, tipo_comision: $vm.tipoComision, comision: comision,
                        descuento: descuento, precio: precio, total: 0, pvp: item.precio, minPersonas: 1
                    });

                })

            }

            else {

                this.tarifaId = horario.tarifa._id;
                let preciosTemp = [...horario.tarifa.precios]

                const idsPrecios = preciosTemp.map(p => p.oldId);

                let preciosModificados = [];
                try {
                    const preciosModificadosResult = await ApiService.getModificadoresDePrecios(idsPrecios);

                    preciosModificados = preciosModificadosResult.data;
                }
                catch (err) {
                    console.log(err);
                }

                $vm.precios = preciosTemp.filter(precio => {
                    if ((precio.activo == null || precio.activo == true) && (precio.web == null || precio.web == true)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })

                if (preciosModificados?.length) {

                    $vm.precios.forEach(p => {

                        let modificaciones = preciosModificados.filter(pm => pm.idPrecio == p.oldId);

                        let modificacionesPorFecha = modificaciones.filter(mp => mp.fechaDeModificador != null);

                        if (modificacionesPorFecha.length > 0) {
                            modificacionesPorFecha.forEach(mp => {
                                const fechaDeDisparo = moment(mp.fechaDeModificador);
                                // const fechaSeleccionada = moment(this.selectedDate).utc().format('YYYY-MM-DD') 
                                const fechaSeleccionada = moment(this.selectedDate).utc().startOf('day');
                                // const today = moment();

                                if (fechaDeDisparo.isSameOrBefore(fechaSeleccionada)) {
                                    p.precio = mp.valor;
                                }
                            })
                        }
                    })
                }

                $vm.selected = []

                $vm.precios.sort((a, b) => {
                    return a.orden - b.orden;
                })

                const _language = this.$store.getters.getIdioma ?? "es";

                $vm.precios.forEach(item => {
                    let pvp = $vm.priceCalculator(item.precio);
                    let comision = null;
                    let descuento = null;

                    this.cantidades.push(0)

                    if ($vm.tipoCobro) {
                        comision = $vm.comision;
                    }
                    else {
                        descuento = $vm.comision
                    }

                    comision = $vm.condicionComercial ? $vm.condicionComercial.valor : null;

                    if (this.monumentoNombres[0].texto === "Castillo de Consuegra") {
                        comision = pvp > 0 ? comision : 0;
                    }

                    descuento = $vm.descuentoComercial ? $vm.descuentoComercial.valor : null;

                    const defaultDesc = item.descripciones.find( d => d.idioma === "es" )?.texto;
                    const _desc = item.descripciones.find( d => d.idioma === _language )?.texto;

                    const _help = item.helpers.find( d => d.idioma === _language)?.texto;
                    const defaultHelp = item.helpers.find( h => h.idioma === "es" )?.texto;
                    item.descripcion = _desc || defaultDesc;
                    item.help = _help || defaultHelp;
                    $vm.selected.push({
                        'cantidad': 0, descripcion: _desc ? _desc : defaultDesc,
                        tipo_comision: $vm.tipoComision, comision: comision, descuento: descuento, precio: item.precio,
                        total: 0, pvp: pvp, minPersonas: item.minPersonas
                    })
                })

            }

            $vm.loading = false;

            return;

        },
        priceCalculator(precio) {
            let $vm = this;

            if ($vm.condicionComercial) {
                if ($vm.condicionComercial.tipo == 1) {

                    if (precio > 0) {
                        return precio + ($vm.condicionComercial.valor * (1 + $vm.iva));
                    }
                    else {
                        if (this.monumentoNombres[0].texto === "Castillo de Consuegra") {
                            return precio;
                        }
                        else {
                            if (this.noChargeForFreeTickets == true) {
                                return precio;
                            }
                            else {
                                return precio + ($vm.condicionComercial.valor * (1 + $vm.iva));
                            }
                        }
                    }
                }
                else {
                    let porcentajeExtra = precio * ($vm.condicionComercial.valor / 100);
                    return precio + ((porcentajeExtra) * (1 + $vm.iva))
                }
            }
            else {
                return precio;
            }
        },
        cancelarOperacion() {
            let $vm = this;
            $vm.itemDuplicadoAlert = false;
            $vm.carritoItemTemp = null;
        },
        duplicarItem() {
            let $vm = this;

            $vm.itemDuplicadoAlert = false;

            $vm.$store.commit('addToCart', $vm.carritoItemTemp);

            // Esta variable hace que no se puedan agregar más entradas con estos mismos datos
            $vm.productoAgregado = true;
            // Esta entrada hace que el icono de carrito parpadeé para llamar la atención;
            $vm.itemAgregado = true;

            var element = this.$refs['checkout'];
            var top = element.offsetTop;

            $vm.carritoItemTemp = null;
            // document.getElementById('resumen-general').scrollIntoView({ behavior:'auto', block:'start', inline:"start" })

        },

        async getEventListClorian(productId, date) {
            let $vm = this;

            $vm.loadingSchedule = true;
            ClorianService.consultarListaDeEventos(productId, date, $vm.monumentoNombres[0].texto)
                .then(res => {

                    $vm.horariosInfo = res.data.eventos.events[0].venueCapacityList.map(horarios => horarios.eventList);

                    $vm.horariosInfo = $vm.horariosInfo[0];
                    // $vm.horarioSelected = {
                    //     _id: $vm.horariosInfo.venueId,
                    //     hora: $vm.horariosInfo.hora
                    // }
                    if ($vm.horariosInfo.length == 1 && $vm.horariosInfo.hora == null) {
                        $vm.setTarifa($vm.horariosInfo[0]);
                    }

                    $vm.horariosInfo.map(horario => {
                        let hora = parseInt(horario.startDatetime);
                        let fullHora = $vm.dateSelected.valueOf() + hora;
                        horario.plazas = Number(horario.capacity)
                        horario.plazasOcupadas = Number(horario.capacity) - Number(horario.totalAvailability)
                        //horario.hora = moment(fullHora).format("YYYY-MM-DDTHH:mm:ss")
                        horario.hora = new Date(hora * 1000).toISOString()
                    })

                    if ($vm.horariosInfo[0].plazas > 0) {
                        $vm.mostrarPlazas = true;
                        $vm.mostrarAforo = true;
                    }

                    $vm.loadingSchedule = false;
                })
                .catch(err => {
                    $vm.loadingSchedule = false;
                    console.log(err);
                })

        },

        async getPricingForEvent(productId, date) {
            let $vm = this;
            ClorianService.getPricingForEvent(productId, date, $vm.monumentoNombres[0].texto)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.log(err);
                })

        },

        async daySelected(day) {
            let $vm = this;
            if (this.selectedDate === null) {
                this.precios = [];
                this.visibleHours = false;
                this.visibleDate = true;
                this.loadingSchedule = false;
                this.horarioSelected = null;
                this.ticketsVisible = false;
                $vm.selected = []
                $vm.precios = [];
                this.resetBoletos();
                this.resumen = [];
                this.visibleCheckout = false;
            }
            else {

                let daySelected = new Date(day.id).toUTCString();

                //   this.$store.commit('resetBoletos')
                this.resetBoletos();
                this.visibleDate = false;
                this.visibleHours = true;

                $vm.loadingSchedule = true;

                let nombreDeMonumentoQuery = $vm.$route.query.monumento;
                $vm.precios = [];
                if (nombreDeMonumentoQuery) {
                    let daySelected = new Date(day.id);

                    daySelected = moment(day.id).startOf('day').format('DD/MM/YYYY')

                    $vm.dateSelected = moment(day.id).startOf('day');

                    await $vm.getEventListClorian($vm.entradaId, daySelected, $vm.monumentoNombres[0].texto)

                    $vm.loadingSchedule = false;
                    // await $vm.getPricingForEvent($vm.entradaId, daySelected);
                }
                else {
                    let infoParaFecha = $vm.disponibilidadInfo.filter(fecha => {
                        let date1 = new Date(fecha.fecha);
                        date1.setUTCHours(0);
                        let dateString = date1.toUTCString();
                        return dateString == daySelected;
                    })

                    let tempHorariosInfo = [...infoParaFecha[0].horarios];
                    let now = moment();

                    $vm.horariosInfo = tempHorariosInfo.filter(hora => {
                        if (hora.hora) {
                            let date = moment.utc(daySelected).format("YYYY-MM-DD");

                            let horario = moment.utc(hora.hora).format("HH:mm:ss");
                            let newHour = moment(date + "T" + horario)

                            if (now.format("YYYY-MM-DD") === date) {
                                var isAfter = moment(newHour).isAfter(now);
                                return isAfter;
                            }
                            else {
                                return true
                            }
                        }
                        else {
                            return true;
                        }
                    })

                    $vm.horarioSelected = null;

                    if ($vm.horariosInfo.length == 1 && $vm.horariosInfo[0].hora == null) {

                        if (this.horariosInfo[0].configuracionAsientos) {
                            try {
                                const result = await ApiService.getZonasConfig(this.horariosInfo[0].configuracionAsientos);
                                this.zonasInfo = [];

                                const zonas = result.data.zonas;

                                this.zonasInfo = zonas.map(z => {

                                    const asientosOcupados = z.asientosOcupados ?? 0;
                                    const asientosReservados = z.asientosReservados ?? 0;
                                    const obj = {
                                        _id: z._id,
                                        name: z.nombre,
                                        color: z.color,
                                        filas: z.filas,
                                        asientos: (z.asientos - asientosOcupados - asientosReservados)
                                    }
                                    return obj;
                                });

                            }
                            catch (err) {
                                console.log(err);
                            }
                        }

                        $vm.setTarifa($vm.horariosInfo[0]);
                        // $vm.loading = false;
                    }
                    else {
                        $vm.selected = []
                        $vm.precios = []
                    }
                    $vm.loadingSchedule = false;
                }

                // document.getElementById('horarios-disponibles').scrollIntoView({ behavior:'smooth', block:'start' })
            }
        },

        validarNumeroEntradas(event, index, valorAnterior) {
            let $vm = this;
            let cantidad = event;

            let _valorAnterior = valorAnterior;

            let plazasOcupadas = 0;
            if ($vm.horarioSelected.plazasOcupadas != null) {
                plazasOcupadas = $vm.horarioSelected.plazasOcupadas;
            }

            let plazasReservadas = $vm.horarioSelected.plazasReservadas != null && $vm.horarioSelected.plazasReservadas >= 0 ? $vm.horarioSelected.plazasReservadas : 0;

            let minPersonas = $vm.selected[index].minPersonas;
            if (cantidad > 0) {
                //   $vm.selected[index].cantidad = cantidad;
                let tempCantidad = 0;

                if (typeof (cantidad) === "string") {
                    tempCantidad = parseInt(tempCantidad);
                }
                else {
                    tempCantidad = cantidad;
                }

                if (tempCantidad == (minPersonas - 1)) {
                    cantidad = 0;
                    tempCantidad = cantidad;
                }
                else if (cantidad < minPersonas) {
                    cantidad = minPersonas;
                    tempCantidad = cantidad;
                }

                if (tempCantidad < minPersonas) {
                    tempCantidad = 0;
                }

                $vm.selected[index].cantidad = tempCantidad;

                let cantidadTotal = this.selected.map(cant => parseInt(cant.cantidad)).reduce((acc, prev) => acc + prev);

                let plazasLibres = $vm.horarioSelected.plazas - (plazasOcupadas + plazasReservadas)

                if (plazasLibres == 0 && ($vm.horarioSelected.plazas != 0 && $vm.horarioSelected.plazas != null)) {
                    this.selected[index].cantidad = 0
                }
                else if ((cantidadTotal > plazasLibres) && ($vm.horarioSelected.plazas != 0 && $vm.horarioSelected.plazas != null)) {
                    this.selected[index].cantidad = plazasLibres;
                }
                this.selected.splice(0, 0);
                //   if( cantidadTotal > ($vm.horarioSelected.plazas - plazasOcupadas - plazasReservadas) && ($vm.horarioSelected.plazas != 0 && $vm.horarioSelected.plazas != null)){
                //       this.selected[index].cantidad = 0;
                //   }

            }
            else {
                cantidad = 0;
                $vm.selected[index].cantidad = 0;
            }

            $vm.selected[index].cantidad = $vm.selected[index].cantidad >= 0 ? $vm.selected[index].cantidad : 0;
        },
        /**
        *  
        */
        async consultarInfoParaReserva() {
            let $vm = this;
            const nombresResult = await ApiService.getEntradaNombres($vm.entradaId, this.selectedIdioma ?? 'es');
            this.siteData = nombresResult.data;
            $vm.nombres = nombresResult.data.nombres
            $vm.monumentoNombres = nombresResult.data.castilloNombres;
            $vm.monumentoId = nombresResult.data.monumentoId;
            $vm.cancelable = nombresResult.data.cancelable;
            $vm.tipoDeEntrada = nombresResult.data.tipo;
            $vm.welcomeActive = nombresResult.data.welcomeActive ?? false;
            $vm.welcomeMessageTitle = nombresResult.data.welcomeMessageTitle ?? "";
            $vm.welcomeMessageSubtitle = nombresResult.data.welcomeMessageSubtitle ?? "";
            $vm.welcomeMessageImage = nombresResult.data.imageUrl ?? "";
            $vm.horario = nombresResult.data.horario ?? "";

            this.restringirVentaIndividual = nombresResult.data.restringirVentaIndividual;
            this.enabledDates = []
            this.precios = [];

            if (this.restringirVentaIndividual === true) {
                this.cargandoDisponibilidad = false;
                this.visitaFirstDate = null;
                this.loading = false;

                this.paquetes = (await ApiService.getPaquetes( this.monumentoId )).data
                .filter( p => p.tipo_de_paquete === 'pack_monumentos')
                .filter( p => p.content.find( c => c.visitaId === this.entradaId ) );
                console.log( this.paquetes );

                throw Error("Solo se puede comprar en paquete");
            }
            if (this.welcomeActive) {
                if (this.consultarBienvenida) {
                    this.mostrarMensajeDeWelcome = true;
                } else {
                    this.mostrarMensajeDeWelcome = false;
                }
            }

            this.consultarBienvenida = false;

            this.seats = nombresResult.data.seats;
            $vm.mostrarAforo = nombresResult.data.mostrarAforo;
            $vm.mostrarPlazas = nombresResult.data.mostrarPlazas;

            const comisionResult = await ApiService.getComision($vm.monumentoId, $vm.entradaId);

            let condicionComercialMonumento = comisionResult.data.condicion_comercial_monumento;
            let condicionComercialVisita = comisionResult.data.condicion_comercial_visita
            this.monumentoConTpv = comisionResult.data.tpvActiva;

            const condicionComercialToApply = condicionComercialVisita?.acuerdoComercial || condicionComercialMonumento?.acuerdoComercial;

            if( condicionComercialToApply ){
                if( condicionComercialToApply.tipo === 1 || condicionComercialToApply.tipo === 3 ){
                    this.tipoCobro = true;
                    this.condicionComercial = condicionComercialToApply;
                    this.descuentoComercial = null;
                    if( condicionComercialToApply.tipo === 1 ){
                        $vm.simbolo = '€';
                    }else{
                        $vm.simbolo = "%";
                    }
                }else{
                    this.tipoCobro = false;
                    this.descuentoComercial = condicionComercialToApply;
                    this.condicionComercial = null;
                }
                this.comision = condicionComercialToApply.valor;
                this.tipoComision = condicionComercialToApply.tipo;
                this.noChargeForFreeTickets = condicionComercialToApply.noChargeForFreeTickets;
                this.metodoDeFacturacion = condicionComercialToApply.metodoDeFacturacion ?? "visita";
                this.autocompensar = condicionComercialToApply.autocompensar ?? true;
            }else{
                this.condicionComercial = {
                    tipo: 1,
                    valor: 1,
                    noChargeForFreeTickets: true,
                    autocompensar: true,
                    metodoDeFacturacion: "visita",
                }
                this.tipoCobro = true;
                this.descuentoComercial = null;
                this.simbolo = '€';
                this.comision = 1;
                this.tipoComision = 1;
                this.noChargeForFreeTickets = true;
                this.metodoDeFacturacion = "visita";
                this.autocompensar = true;
            }
            // if (condicionComercialVisita.tipoComision != null || condicionComercialVisita.tipoDescuento != null) {

            //     $vm.condicionComercial = condicionComercialVisita.tipoComision;

            //     $vm.descuentoComercial = condicionComercialVisita.tipoDescuento;

            //     this.noChargeForFreeTickets = true;
            //     this.metodoDeFacturacion = condicionComercialVisita.tipoDeFacturacion ?? 'visita';

            //     if (condicionComercialVisita.tipoComision) {
            //         $vm.tipoCobro = true;
            //         $vm.comision = condicionComercialVisita.tipoComision.valor;
            //         $vm.tipoComision = condicionComercialVisita.tipoComision.tipo;
            //         if ($vm.tipoComision?.tipo === 1 || $vm.tipoComision?.tipo === 3) {
            //             $vm.simbolo = '€';
            //         }
            //         else {
            //             $vm.simbolo = "%";
            //         }
            //         this.autocompensar = this.condicionComercial.autocompensar ?? true;
            //     }
            //     else {
            //         $vm.tipoCobro = false;
            //         $vm.comision = condicionComercialVisita.tipoDescuento.valor
            //         $vm.tipoComision = condicionComercialVisita.tipoDescuento.tipo;
            //         this.autocompensar = this.descuentoComercial.autocompensar ?? true;
            //     }
            // }
            // else {
            //     $vm.condicionComercial = condicionComercialMonumento.tipoComision;
            //     $vm.descuentoComercial = condicionComercialMonumento.tipoDescuento;
            //     // this.noChargeForFreeTickets = condicionComercialMonumento.noChargeForFreeTickets ?? false;
            //     this.noChargeForFreeTickets = true;
            //     this.metodoDeFacturacion = condicionComercialMonumento.tipoDeFacturacion ?? 'visita';

            //     if (this.descuentoComercial) {
            //         this.autocompensar = this.descuentoComercial?.autocompensar ?? true;
            //     } else if (this.condicionComercial) {
            //         this.autocompensar = this.condicionComercial?.autocompensar ?? true;
            //     } else {
            //         this.autocompensar = true;
            //     }

            //     if (condicionComercialMonumento.tipoComision) {
            //         $vm.tipoCobro = true;
            //         $vm.comision = condicionComercialMonumento.tipoComision.valor;
            //         $vm.tipoComision = condicionComercialMonumento.tipoComision.tipo;
            //         this.autocompensar = this.condicionComercial?.autocompensar ?? true;

            //         if ($vm.tipoComision?.tipo === 1 || $vm.tipoComision?.tipo === 3) {
            //             $vm.simbolo = '€';
            //         }
            //         else {
            //             $vm.simbolo = "%";
            //         }

            //     }
            //     else {
            //         $vm.tipoCobro = false;
            //         this.autocompensar = this.descuentoComercial?.autocompensar ?? true;
            //         $vm.comision = this.descuentoComercial?.valor ?? 0;
            //         $vm.tipoComision = this.descuentoComercial?.tipo ?? 2
            //     }
            // }


            await $vm.getFechas(new Date())

            const eventsResult = await ApiService.getEventosByMonumento($vm.$i18n.locale, $vm.monumentoId)
            this.eventosRecomendados = [...eventsResult.data.filter( e => e.restringirVentaIndividual !== true )];

        },
        async consultarProductDetails() {
            let $vm = this;
            let monumento = $vm.$route.query.monumento;
            let productId = $vm.$route.params.entradaId;
            $vm.pos = $vm.$route.query.pos;

            let tarifas = null;
            ClorianService.consultarDetallesProducto(productId, monumento, $vm.pos)
                .then(res => {
                    $vm.nombres[0].texto = res.data.productDetails.product.name
                    tarifas = res.data.productDetails.buyerTypeList;
                    // $vm.pos = res.data.pos
                    $vm.clorianRatesInfo = tarifas;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        async consultarInfoClorian(queryMonth) {
            let $vm = this;
            let monumento = $vm.$route.query.monumento;
            let productId = $vm.$route.params.entradaId;

            let today = new Date();
            let tempYear = today.getUTCFullYear();
            let tempMonth = today.getUTCMonth();

            let year = tempYear.toString();

            let month = (tempMonth + 1).toString();

            if (month.length < 2) {
                month = '0' + month;
            }

            if (queryMonth) {

                month = (queryMonth).toString();

                if (month.length < 2) {
                    month = '0' + month;
                }
            }

            $vm.loading = false;
            // Consultamos la disponiblidad para este mes
            ClorianService.consultarFechasProducto(productId, monumento, year, month)
                .then(res => {
                    //$vm.nombres[0].texto = res.data.eventos ? res.data.eventos.events[0].name: $vm.nombres[0].texto;

                    var clorianAvailableDates = [];
                    // Convertimos las fechas
                    var clorianFullDates = Object.keys(res.data.fechasDisponibles);

                    clorianFullDates.forEach(element => {
                        if (res.data.fechasDisponibles[element] === "availability") {
                            clorianAvailableDates.push(element);
                        }
                    })
                    // Comparamos a partir del día de hoy
                    var today = new Date();

                    today = moment(today).startOf('day').format('YYYY-MM-DD');
                    $vm.visitaFirstDate = clorianAvailableDates[0];

                    $vm.enabledDates = [];
                    clorianAvailableDates.forEach(element => {

                        let tempDate = moment(element).format('YYYY-MM-DD');

                        if (tempDate >= today) {
                            let dateObject = { 'start': element, 'end': element }
                            $vm.enabledDates.push(dateObject)
                        }
                    })

                    $vm.loading = false;
                })
                .catch(err => {
                    $vm.loading = false;
                    console.log(err);
                })
        }

    },
    setup() {

    },
    computed: {
        horarioParaResumen() {
            const hora = this.horarioSelected ? this.horarioSelected.hora ? new Date(this.horarioSelected.hora).toLocaleTimeString('es-ES', { 'timeZone': 'UTC', hour: '2-digit', minute: '2-digit' }) : this.$t("default.FREE_SCHEDULE_LABEL") : ""
            return hora;
        },

        getTicketsAmount() {
            let personas = this.selected.map(item => item.cantidad).reduce((acc, curr) => acc + curr, 0)
            return personas;
        },
        horarios() {
            let horariosSorted = this.horariosInfo;
            horariosSorted.sort(function (a, b) {
                return new Date(a.hora) - new Date(b.hora)
            })
            return horariosSorted;
        },
        horariosLimitados() {
            if (this.horariosInfo?.length) {
                const firstHour = this.horariosInfo[0];
                if (!firstHour.hora){
                    return ({hide: false, note: firstHour.notaDeVenta})
                }
            }
            return ({hide: true, note: ''})
        },
        selectedIdiomaIndex() {
            let $vm = this;
            let idiomaIndex = 0;
            if ($vm.$i18n.locale == 'es') {
                idiomaIndex = 0;
            }
            else if ($vm.$i18n.locale == 'en') {
                idiomaIndex = 1;
            }
            else if ($vm.$i18n.locale == 'fr') {
                idiomaIndex = 2;
            }
            else if ($vm.$i18n.locale == 'de') {
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma() {
            let $vm = this;

            return this.$store.getters.getIdioma ?? 'es';
        },
        habilitarPasoACarrito() {
            const personas = this.selected.map(item => item.cantidad).reduce((acc, curr) => acc + curr, 0)

            if ((this.total ?? 0) > 0 || personas > 0 && this.consultandoDisponibilidad === false) {
                return true;
            } else {
                return false;
            }
        }
    },
    async created() {
        try {
            let $vm = this;
            this.resetBoletos();
            $vm.entradaId = $vm.$route.params.entradaId;

            this.consultarBienvenida = this.$route.query?.direct ?? false;
            const tipoDeReserva = this.$route.query?.paquete ?? false;
            $vm.cargandoDisponibilidad = true;

            let nombreDeMonumentoQuery = $vm.$route.query.monumento;

            if (nombreDeMonumentoQuery == null) {
                await $vm.consultarInfoParaReserva();
            }
            else {
                await $vm.consultarProductDetails();
                $vm.consultarInfoClorian();
                $vm.monumentoNombres[0].texto = nombreDeMonumentoQuery
                $vm.clorian = true;
            }

            if ($vm.$route.query.cancel) {
                $vm.reservaCancelada = true;
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.movingToCart = false;
        }
    }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; */
    margin: 0;
}

/* Firefox */
/* input[type=number] {
-moz-appearance: textfield;
} */

.horario-selected {
    background-color: rgba(204, 255, 255, 0.5) !important;
}

.center {
    /* position: fixed; */
    position: relative;
    /* top: 50%; */
    /* bottom: 0; */
    /* left: 50%; */
    /* right: 0; */
    /* margin: auto; */
    transform: translate(-50%, -50%);
    z-index: 10;
}

.collapsable-header {
    border-radius: 10px;
    background: #D9D9D9;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    /* max-width: 520px; */
}

.header-title-container {
    display: flex;
    height: 55px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header-typhograpy {
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.header-active-typhograpy {
    color: var(--spainhn-buy-button);
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.finish-button {
    display: flex;
    padding: 5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 20px;
    background: #850B0B !important;
}

.finish-text {
    display: flex;
    width: 290px;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>

<style>
.reservasV2-date-picker .vc-day-content[data-v-4420d078]:hover {
    background-color: var(--spainhn-rojo-grisaceo) !important;
    color: #fff;
}

.loader-component {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>