var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LayoutDefault px-0 pt-0", staticStyle: { width: "100%" } },
    [
      _c(
        "mdb-navbar",
        {
          staticClass: "fw-bold bg-white sticky-top py-2",
          attrs: { light: "", expand: "xl" },
        },
        [
          _c("mdb-navbar-brand", [
            _c("img", {
              staticClass: "align-text-top d-inline-block",
              staticStyle: { height: "80px", width: "auto" },
              attrs: {
                src: require("../assets/LOGO REPAHIS.png"),
                role: "button",
              },
              on: { click: () => _vm.$router.push("/") },
            }),
          ]),
          _c(
            "mdb-navbar-toggler",
            [
              _c(
                "mdb-navbar-nav",
                { attrs: { right: "" } },
                [
                  _c(
                    "mdb-nav-item",
                    { staticClass: "ms-auto" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { to: "/" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_INICIO")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "mdb-nav-item",
                    { staticClass: "nav-item border-nd-start ms-auto" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/castillos" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_MONUMENTOS")))]
                      ),
                    ]
                  ),
                  _c(
                    "mdb-nav-item",
                    { staticClass: "nav-item h-100 border-md-start ms-auto" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/rutas-recomendadas" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_RUTAS")))]
                      ),
                    ]
                  ),
                  _c(
                    "mdb-nav-item",
                    { staticClass: "nav-item border-md-start ms-auto" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/blog" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_NOTICIAS")))]
                      ),
                    ]
                  ),
                  _c(
                    "mdb-dropdown",
                    {
                      staticClass:
                        "nav-item dropdown dropdown2 my-auto border-md-start ms-auto",
                      attrs: { dropleft: "", color: "danger" },
                    },
                    [
                      _c(
                        "mdb-dropdown-toggle",
                        {
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: {
                            slot: "toggle",
                            tag: "a",
                            navLink: "",
                            "waves-fixed": "",
                          },
                          slot: "toggle",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("default.MAIN_MENU_SERVICIOS")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "mdb-dropdown-menu",
                        [
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/servicios/visitas" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.MAIN_MENU_SERVICES_VISITS")
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/servicios/alojamiento" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "default.MAIN_MENU_SERVICES_ALOJAMIENTO"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/servicios/restaurantes" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "default.MAIN_MENU_SERVICES_RESTAURANTES"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  href: "/castillos/servicios/eventos-singulares",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.MAIN_MENU_SERVICES_EVENTOS")
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  href: "/castillos/servicios/experiencias-exclusivas",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "default.MAIN_MENU_SERVICES_EXPERIENCIAS"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/servicios/bodas" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.MAIN_MENU_SERVICES_BODA")
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/servicios/espacios" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "default.MAIN_MENU_SERVICES_ESPACIOS"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "/servicios/exposiciones" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.MAIN_MENU_SERVICES_MUSEOS")
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "mdb-nav-item",
                    { staticClass: "nav-item border-md-start ms-auto" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/clientes/login" },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mt-1 me-1",
                            attrs: { icon: "fa-circle-user" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("default.MAIN_MENU_ACCEDER")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "mdb-dropdown",
                    {
                      staticClass:
                        "my-auto border-md-start d-md-flex ms-auto d-none dropdown ms-auto",
                      attrs: { color: "danger", end: "" },
                    },
                    [
                      _c(
                        "mdb-dropdown-toggle",
                        {
                          attrs: {
                            slot: "toggle",
                            tag: "a",
                            navLink: "",
                            "waves-fixed": "",
                          },
                          slot: "toggle",
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "my-auto",
                            class: { "fa-bounce": _vm.nuevoItemAgregado },
                            attrs: { icon: "fa-cart-shopping" },
                          }),
                          _vm._v(" " + _vm._s(_vm.ticketsAmount) + " "),
                        ],
                        1
                      ),
                      _c(
                        "mdb-dropdown-menu",
                        [
                          _vm._l(_vm.itemsInCart, function (item) {
                            return _c(
                              "mdb-dropdown-item",
                              { key: item.uuid, staticClass: "dropdown-item" },
                              [
                                _c("entrada-resumen", {
                                  staticClass: "me-auto",
                                  attrs: { item: item },
                                }),
                              ],
                              1
                            )
                          }),
                          _c("mdb-dropdown-item", [
                            _c("hr", { staticClass: "dropdown-divider" }),
                          ]),
                          _c("mdb-dropdown-item", [
                            _c("a", { staticClass: "dropdown-item nav-link" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.MENSAJE_IMPORTE_TOTAL")
                                  ) +
                                    " " +
                                    _vm._s(_vm.totalAPagar.toLocaleString()) +
                                    " €"
                                ),
                              ]),
                            ]),
                          ]),
                          _c("mdb-dropdown-item", { staticClass: "mx-auto" }, [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                attrs: { href: "/carrito" },
                              },
                              [
                                _c("strong", { staticClass: "mx-auto" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("default.MENSAJE_VER_CARRITO")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "mdb-dropdown-item",
                            {
                              staticClass: "d-flex py-0",
                              class: { "d-none": !_vm.mostrarTiempo },
                            },
                            [
                              _c("hr"),
                              _c("p", { staticClass: "ms-auto me-2" }, [
                                _vm._v(_vm._s(_vm.timeCounter)),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "ms-auto ms-lg-0 d-flex" },
                [
                  _c(
                    "mdb-dropdown",
                    { staticClass: "ms-auto", attrs: { end: "" } },
                    [
                      _c(
                        "mdb-dropdown-toggle",
                        {
                          staticClass: "text-uppercase",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: {
                            slot: "toggle",
                            tag: "a",
                            navLink: "",
                            "waves-fixed": "",
                          },
                          slot: "toggle",
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "20px",
                              "margin-right": "8px",
                              height: "auto",
                            },
                            attrs: {
                              src: _vm.selectedFlag,
                              alt: "selected language flag",
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.selectedLanguage) + " "),
                        ]
                      ),
                      _c(
                        "mdb-dropdown-menu",
                        { staticClass: "ms-auto text-uppercase" },
                        _vm._l(_vm.availableLanguages, function (lang) {
                          return _c(
                            "mdb-dropdown-item",
                            {
                              key: lang.code,
                              on: {
                                click: () => _vm.changeLanguage(lang.code),
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "20px",
                                  margin: "8px",
                                  height: "auto",
                                },
                                attrs: { src: lang.flag, alt: lang.name },
                              }),
                              _vm._v(" " + _vm._s(lang.name) + " "),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "main",
        { staticClass: "LayoutDefault__main bg-light container-fluid px-0" },
        [
          _vm._t("default"),
          _c(
            "div",
            {
              staticClass:
                "justify-content-start d-none fixed-button big-text me-4",
              class: { "d-lg-flex": _vm.mostrarTiempo },
              attrs: { role: "button" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "p-2 bg-white rounded opacity-75 ms-3 mb-3 shadow-lg d-flex",
                  on: {
                    click: () => {
                      _vm.$router.push("/carrito")
                    },
                  },
                },
                [
                  _c(
                    "p",
                    { staticClass: "ms-2 me-2 my-auto font-weight-bold" },
                    [_vm._v(_vm._s(_vm.timeCounter))]
                  ),
                  _c("font-awesome-icon", {
                    staticClass: "text-align-start",
                    attrs: {
                      size: "3x",
                      icon: "fa-clock me-auto ms-3 my-auto",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "fixed-button d-lg-none d-flex" }, [
            _c(
              "div",
              {
                ref: "carrito",
                staticClass:
                  "bg-white opacity-75 rounded ms-3 shadow-lg d-flex",
                attrs: { role: "button" },
                on: {
                  click: () => {
                    _vm.$router.push("/carrito")
                  },
                },
              },
              [
                _c("div", { class: { "d-none": !_vm.mostrarTiempo } }, [
                  _c("small", { staticClass: "ms-auto me-2 big-text pe-2" }, [
                    _vm._v(_vm._s(_vm.timeCounter)),
                  ]),
                ]),
                _c("font-awesome-icon", {
                  staticClass: "text-align-start my-2 ps-2",
                  class: { "fa-bounce": _vm.nuevoItemAgregado },
                  attrs: { size: "2x", icon: "fa-cart-shopping me-auto" },
                }),
                _c("span", { staticClass: "big-text" }, [
                  _vm._v(_vm._s(_vm.ticketsAmountSmall)),
                ]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "d-flex w-100 newsletter py-md-5",
          staticStyle: { "min-height": "18rem" },
          style: { backgroundImage: _vm.newsletterImage },
        },
        [
          _c("div", {
            staticClass: "d-md-flex d-none mx-auto my-auto",
            staticStyle: {
              "min-height": "341px",
              width: "28vw",
              "min-width": "300px",
            },
          }),
        ]
      ),
      _c(
        "footer",
        { staticClass: "LayoutDefault_footer bg-dark text-white pb-2" },
        [
          _c(
            "mdb-row",
            {
              staticClass: "pt-4 footer-wrapper m-0",
              staticStyle: {
                "padding-bottom": "5.5rem",
                "justify-content": "center",
              },
            },
            [
              _c(
                "mdb-col",
                {
                  staticClass: "ps-md-4 ps-0 mb-md-4 mb-md-0 px-md-5",
                  attrs: { col: "6", lg: "3" },
                },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("@/assets/LOGO REPAHIS (BLANCO SIN FONDO).png"),
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex d-md-none flex-column align-items-start text-start mt-4 text-start spainhn-text",
                    },
                    [
                      _c("div", { staticClass: "d-flex mt-3 text-start" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: {
                              href: "https://www.facebook.com/castillosypalaciosdeespana",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-facebook-square",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: { href: "https://twitter.com/redcastillos" },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-twitter-square",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: {
                              href: "https://www.instagram.com/redcastillosypalacios/",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-instagram-square",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: {
                              href: "https://www.linkedin.com/company/asociaci%C3%B3n-y-club-de-producto-castillos-y-palacios-tur%C3%ADsticos-de-espa%C3%B1a/",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-linkedin",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "mdb-col",
                { staticClass: "px-1", attrs: { col: "6", lg: "3" } },
                [
                  _c("div", { staticClass: "d-flex align-items-start" }, [
                    _c(
                      "small",
                      {
                        staticStyle: {
                          "font-size": "10px",
                          "text-align": "left",
                        },
                      },
                      [_vm._v("SPAIN HERITAGE NETWORK")]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column mt-1 text-start spainhn-text",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/quienes-somos" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.FOOTER_WHO")))]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/aviso-legal" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.FOOTER_LEGAL")))]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/privacidad-cookies" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("default.FOOTER_PRIVACY_AND_COOKIES")
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/condiciones-generales" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.FOOTER_SELL_GENERALS"))
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/contacto" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("default.FOOTER_CONTACT")))]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/politica-calidad" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [_vm._v("Política de gestión de calidad")]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/politica-seguridad-informacion" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [_vm._v("Política de seguridad de la información")]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "/politica-responsabilidad-legal" },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [_vm._v("Política de complience legal")]
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: {
                            href: "https://admin.spainheritagenetwork.com",
                          },
                        },
                        [
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "9px",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("default.FOOTER_ACCESS")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "mdb-col",
                {
                  staticClass: "ps-0 ps-md-4 d-none d-md-inline-block",
                  attrs: { sm: "6", lg: "3" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-start text-start" },
                    [
                      _c(
                        "small",
                        {
                          staticClass: "fw-bold",
                          staticStyle: { "font-size": "small" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.FOOTER_CONTACT_TITLE")))]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-items-start text-start mt-3 text-start spainhn-text",
                    },
                    [
                      _c("small", [
                        _vm._v("Email: info@spainheritagenetwork.com"),
                      ]),
                      _c("div", { staticClass: "d-flex mt-3 text-start" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: {
                              href: "https://www.facebook.com/castillosypalaciosdeespana",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-facebook-square",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: { href: "https://twitter.com/redcastillos" },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-twitter-square",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: {
                              href: "https://www.instagram.com/redcastillosypalacios/",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-instagram-square",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "link-light",
                            attrs: {
                              href: "https://www.linkedin.com/company/asociaci%C3%B3n-y-club-de-producto-castillos-y-palacios-tur%C3%ADsticos-de-espa%C3%B1a/",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "me-2",
                              attrs: {
                                icon: "fa-brands fa-linkedin",
                                size: "2x",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "mdb-row",
            [
              _c(
                "mdb-col",
                {
                  staticClass: "ps-4 mb-4 mb-md-0",
                  attrs: { col: "12", md: "6" },
                },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    staticStyle: { width: "320px", height: "auto" },
                    attrs: {
                      src: require("@/assets/LOGO_NEXTGENERATIONEU.jpg"),
                    },
                  }),
                ]
              ),
              _c(
                "mdb-col",
                {
                  staticClass: "ps-4 mb-4 mb-md-0",
                  attrs: { col: "12", md: "6" },
                },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    staticStyle: { width: "320px", height: "auto" },
                    attrs: { src: require("@/assets/MIT_Gob.Web_SET.png") },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }